<template>
    <base-entity
        :config="{
            backRouteName: 'drivers.index',
            getEntity: getEntity,
            onSubmit: onSubmit,
            showControlButtons: true
        }"
    >
        <template v-slot:title="{ isUpdateMode }">
            {{ isUpdateMode ? 'Edit Driver' : 'Create Driver'}}
        </template>

        <template v-slot:form="{ isUpdateMode }">
            <b-row>
                <b-col sm="4">
                    <base-input
                        v-model="entity.name"
                        name="name"
                        :rules="{ required: true }"
                        required
                        label="Name" size="sm">
                    </base-input>
                </b-col>
                 <b-col sm="4">
                    <base-input
                        v-model="entity.code"
                        name="code"
                        :rules="{ required: true }"
                        required
                        label="Code" size="sm">
                    </base-input>
                </b-col>
                <b-col sm="4">
                    <base-input
                        v-model="entity.phone"
                        type="text"
                        name="phone"
                        :rules="{ required: true }"
                        required
                        label="Phone">
                    </base-input>
                </b-col>
                <b-col sm="2">
                    <fieldset class="form-group">
                        <div>
                            <label class="form-control-label"> State </label>
                            <div class="has-label">
                                <el-select
                                    filterable
                                    placeholder="-- Select --"
                                    v-model="entity.state"
                                >
                                    <el-option v-for="option in states"
                                            class="select-danger"
                                            :value="option.value"
                                            :label="option.label"
                                            :key="`${ option.value }`">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </fieldset>
                </b-col>
                <b-col sm="2">
                    <fieldset class="form-group">
                        <div>
                            <label class="form-control-label"> Identity Type </label>
                            <div class="has-label">
                                <el-select
                                    filterable
                                    placeholder="-- Select --"
                                    v-model="entity.identity_type"
                                >
                                    <el-option v-for="option in identityTypes"
                                            class="select-danger"
                                            :value="option.value"
                                            :label="option.label"
                                            :key="`${ option.value }`">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </fieldset>
                </b-col>
                <b-col sm="4">
                    <base-input
                        v-model="entity.identity"
                        type="text"
                        name="identity"
                        label="Identity">
                    </base-input>
                </b-col>
                <b-col sm="4" v-if="!isUpdateMode">
                    <base-input
                        v-model="entity.password"
                        type="password"
                        name="password"
                        :rules="{ required: true }"
                        required
                        label="Password">
                    </base-input>
                </b-col>
            </b-row>
            
            <div v-for="(operation, index) in operations" :key="operation + index">
                <hr>
                <h3>{{ `Operation ${operation.name}` }}</h3>
                <b-row v-for="(category, index) in entity.categories" :key="index">
                    <b-col sm="6" v-if="operation.id === category.operation_id">
                        <base-input
                            v-model="entity.categories[index]['delivery_fee_usd']"
                            type="number"
                            step="any"
                            name="delivery_fee"
                            :label="`${ category.name } (USD)`">
                        </base-input>
                    </b-col>
                    <b-col sm="6" v-if="operation.id === category.operation_id">
                        <base-input
                            v-model="entity.categories[index]['delivery_fee_riel']"
                            type="number"
                            step="any"
                            name="delivery_fee"
                            :label="`${ category.name } (Riel)`">
                        </base-input>
                    </b-col>
                </b-row>
            </div>
        </template>
    </base-entity>
</template>
<script>
import BaseEntity from '@/components/Custom/BaseEntity';
import { Select, Option } from 'element-ui';
import store from './store';
export default {
    data() {
        return {
            entity: {
                state: 1,
                name: '',
                code: '',
                password: '',
                phone: '',
                email: '',
                categories: []
            },
            categories: [],
            identityTypes: [
                {
                    label: 'ID Card',
                    value: 'id-card'
                },
                {
                    label: 'Passport',
                    value: 'passport'
                },
                {
                    label: 'Other',
                    value: 'other'
                }
            ],
            states: [
                {
                    label: 'Active',
                    value: 1
                },
                {
                    label: 'Suspend',
                    value: 0
                }
            ],
        }
    },
    methods: {
        getCategories() {
            store.dispatch('getCategories').then(({ data }) => {
                this.entity.categories = data.map(category => {
                    return {
                        id: category.id,
                        name: category.name,
                        operation_id: category.operation_id,
                        delivery_fee_usd: 0,
                        delivery_fee_riel: 0
                    }
                });
            });
        },
        getOperations() {
            store.dispatch('getOperations')
        },
        onSubmit(id) {
            if (id) {
                store.dispatch('update', this.entity).then(() => {
                    this.toastSuccess('Driver Updated');
                    this.$router.push({ name: 'drivers.index' });
                });
            } else {
                store.dispatch('store', this.entity).then(() => {
                    this.toastSuccess('Driver Created');
                    this.$router.push({ name: 'drivers.index' });
                });
            }
        },
        getEntity(id) {
            store.dispatch('show', id).then(({ data }) => {
                this.entity = {
                    ...data,
                    categories: this.entity.categories.map(c => {
                        const currentCategory = data.categories.find(item => item.id === c.id);
                        return {
                            id: c.id,
                            name: c.name,
                            operation_id: c.operation_id,
                            delivery_fee_usd: currentCategory ? +currentCategory.pivot.delivery_fee_usd : 0,
                            delivery_fee_riel: currentCategory ? +currentCategory.pivot.delivery_fee_riel : 0,
                        }
                    })
                };
            });
        },
    },
    mounted() {
        this.getCategories();
        this.getOperations();
    },
    computed: {
        operations() {
            return store.getters.operations;
        }
    },
    components: {
        BaseEntity,
        [Select.name]: Select,
        [Option.name]: Option,
    }
}
</script>

<style scoped>
hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
</style>